import api from './api';


class ImprovementService {
    async getImprovements(cloud) {
        var improvements_list = []

        const response = await api.get("/improvements/"+cloud);

        improvements_list = response.data

        return improvements_list;
    }
    async getImprovementStatus(cloud) {
        var improvementStatus = {}

        const response = await api.get("/improvements/"+cloud+"/status");

        improvementStatus = response.data

        return improvementStatus;
    }
    async getImprovementLock(cloud) {
        var improvementLock = {}

        const response = await api.get("/improvements/"+cloud+"/lock");

        improvementLock = response.data

        return improvementLock;
    }    
    async getImprovementsByLensPillar(cloud, lens_alias, pillar_id) {
        var improvements_list = []

        const response = await api.get("/improvements/"+cloud+"/"+lens_alias+"/"+pillar_id);
      
        improvements_list = response.data       

        return improvements_list;
    }
    async getImprovementByLensPillarChoice(cloud, lens_alias, pillar_id, choice_id) {
        var improvements_list = []

        const response = await api.get("/improvements/"+cloud+"/"+lens_alias+"/"+pillar_id+"/"+choice_id);

        improvements_list = response.data       

        return improvements_list;
    }    
    async triggerImprovementsUpdater() {

        const response = await api.post("/improvements/update", {} );

        return response.data ;
    }  
    
  }
  
  export default new ImprovementService();