<template>
  <CCol>
    <CToaster :autohide="2000">
      <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>      
      <template>           
        <div v-if="showInfoToast">
          <CToast          
          :show.sync="showInfoToast"
          color="info"
          >
          {{ infoToastMessage  }}            
          </CToast>
        </div>   
      </template>                 
    </CToaster>         
    <CRow v-show="loading">
      <CCol col="10">
        <CCard v-show="loading">
          <CCardBody>
            <p class="text-center" v-show="loading">
              <CSpinner />
            </p >   
          </CCardBody>
        </CCard>
      </CCol>    
    </CRow>
  <CRow v-show="!loading">     
    <CCol col="10">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol col="2">
              <CButton class="float-left" disabled variant="ghost" color="dark">Improvement Items</CButton>
            </CCol>
            <CCol col="6">
              <template>              
                  <div class='float-left col-xl-6'>
                    <multiselect 
                      v-model="selectedLens"
                      :options="lenses" 
                      track-by="lens_alias"
                      label="lens_name" 
                      :searchable="false" 
                      :close-on-select="true"   
                      placeholder="Select Lens to filter..."
                      selectLabel=""
                      :multiple="false"
                      :hideSelected="false"                  
                      :allowEmpty="true"
                      :loading="loadingLenses"
                      @input="setFilterLens"
                      :custom-label="lensSelectCustomLabel"
                    />                          
                  </div>
                </template>  
                <template>              
                  <div class='float-left col-xl-6'>
                    <multiselect 
                      v-model="selectedPillar"
                      :options="pillars" 
                      track-by="pillar_id"
                      label="pillar_name" 
                      :searchable="false" 
                      :close-on-select="true"   
                      placeholder="Select Pillar to filter..."
                      selectLabel=""
                      :multiple="false"
                      :hideSelected="false"                  
                      :allowEmpty="true"
                      :loading="loadingPillars"
                      @input="setFilterPillar"
                    />                          
                  </div>
                </template>                  
            </CCol>
            <CCol col="4">
              <CButton class="ml-5 float-right" @click="openHelp(true)" color="dark" :disabled="true"><span class="material-icons md-18">question_mark</span></CButton>
              <CButton class="ml-5 float-right" :color="transformUpdateStatusToColor()" :disabled="true" v-c-tooltip="{ content: transformUpdateStatus() }" >Status</CButton>              
              <CButton class="ml-5 float-right" variant="outline" @click="updateImprovements" :disabled="checkIfUpdateIsLocked()" color="info" v-c-tooltip="{ content: 'Starts a background process to scrape all improvements from AWS. Will be locked during execution.' }">
                <div>
                <span style="vertical-align: text-bottom;" class="material-icons md-18 icon align">
                <template v-if="checkIfUpdateIsLocked()">
                  lock
                </template>
                <template v-else>
                  lock_open
                </template>
                </span>
                 Update DB 
                </div> 
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>      
        <CCardBody>                 
          <CDataTable
            hover
            striped
            sorter            
            cleaner
            column-filter
            items-per-page-select
            :column-filter-value="{ 'lens_alias': selectedLensAlias, 'pillar_id': selectedPillarId }"
            :sorter-value="{ column: 'lens_alias', asc: 'true' }"
            :items="items"
            :fields="fields"
            :items-per-page="20"      
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            :loading=loadingImprovementItemsTable
          >                         
              <td slot="lens_alias" slot-scope="{item}" >
                  {{ getLensName(item.lens_alias) }}
              </td>               

              <td slot="improvement_guidance_url" slot-scope="{item}"> 
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <span class="d-inline-block text-truncate" style="max-width: 250px;">
                    {{ item.improvement_guidance_url }}                  
                  </span>  
                  <div class="custom-tooltip" v-show="showTooltip">
                    {{ item.improvement_guidance_url }}                  
                  </div>
                </div>        
              </td> 

              <td slot="choice_name" slot-scope="{item}">
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <span class="d-inline-block text-truncate" style="max-width: 400px;">
                  {{ item.choice_name }}
                  </span>
                  <div class="custom-tooltip" v-show="showTooltip">
                    {{ item.choice_name }}
                  </div>
                </div>                   
              </td> 

              <td slot="pillar_id" slot-scope="{item}" >
                  {{ getPillarName(item.pillar_id) }}
              </td> 

              <td slot="improvement_view" slot-scope="{item}"> 
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <CButton v-on:click="viewImprovement(item)" :id="item.improvement_id"  color="dark" >
                    <CIcon name="cil-magnifying-glass"/>
                  </CButton>
                  <div class="custom-tooltip custom-tooltip-left" v-show="showTooltip">
                    Opens the scraped Improvement Item without HTML.
                  </div>
                </div>                                  
              </td>  

              <td slot="improvement_view_html" slot-scope="{item}"> 
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <CButton v-on:click="viewImprovementHtml(item.improvement_cloud, item.lens_alias, item.pillar_id, item.choice_id)" :id="item.improvement_id"  color="dark">
                    <CIcon name="cil-magnifying-glass"/>
                  </CButton>
                  <div class="custom-tooltip custom-tooltip-left" v-show="showTooltip">
                    Opens the scraped HTML block. Will be loaded from the DB when opened.
                  </div>
                </div>                   
              </td>              

          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
    <CModal
      size="lg"
      :title="viewImprovementItem.choice_name"
      color="dark"
      :show.sync="viewImprovementModal"
    >
    <CRow col="12">
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <strong>Question ID / Choice ID</strong>
          </CCardHeader>
          <CCardBody>
            {{ viewImprovementItem.question_id }} / {{ viewImprovementItem.choice_id }}
          </CCardBody>
        </CCard>
      </CCol>      
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <strong>Lense / Pillar</strong>
          </CCardHeader>
          <CCardBody>
            {{ getLensName(viewImprovementItem.lens_alias) }} / {{ getPillarName(viewImprovementItem.pillar_id) }}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      <CCard>
        <CCardHeader>
          <strong>Improvement Guidance URL</strong>
        </CCardHeader>
        <CCardBody>
          <CRow col="12">
            <CCol col="10">
              <CInput                      
                placeholder="No URL available"
                v-model="viewImprovementItem.improvement_guidance_url"
                disabled
              >
                <template #prepend-content><CIcon name="cil-link"/></template>
              </CInput>
            </CCol>
            <CCol col="1">
              <CButton  
                :id="viewImprovementItem.improvement_guidance_url"
                :href="viewImprovementItem.improvement_guidance_url"
                :disabled="viewImprovementItem.improvement_guidance_url === undefined"
                target="_blank"
                color="info"
                style="display: inline-grid;">
                <span class="material-icons md-18">link</span>
              </CButton>
            </CCol>
            <CCol col="1">
              <CButton 
                :id="viewImprovementItem.improvement_guidance_url"
                :disabled="viewImprovementItem.improvement_guidance_url === undefined"
                v-on:click="copyToClipBoard(viewImprovementItem.improvement_guidance_url)" 
                color="info"
                style="display: inline-grid;">                      
                  <span class="material-icons md-18">content_copy</span>
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>        
      <div slot="footer" class="w-100">
          <CButton @click="viewImprovementModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-right" >Close</CButton>  
      </div>
    </CModal> 
    <CModal
      size="xl"
      :title="viewImprovementHtmlItem.choice_name"
      color="dark"
      :show.sync="viewImprovementHtmlModal"
    >
      <template>
        <span class="m-2">
          <i>The HTML preview design/layout does not match the final look in the report!</i>
        </span>
      </template>
      <p class="mt-2"> 
        <quill-editor
          label="Description"
          ref="myQuillEditor"
          :value="viewImprovementItemHtmlPlain"                
          :options="editorOption"
          :disabled="true"
        /> 
      </p>
      <div slot="footer" class="w-100">
          <CButton @click="viewImprovementModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-right" >Close</CButton>  
      </div> 
    </CModal> 
  </CCol>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ImprovementService from '@/services/improvement.services';
import LensService from '@/services/lense.services';
import ASelect from '@/components/forms/ASelect.vue';

export default {
  components: {
    ASelect,
    Multiselect
  },  
  data() {
    return {
      loading: true,
      items: [],
      fields: [
        { key: 'choice_name', label: 'Name', _style:'width:20%' },
        { key: 'lens_alias', label: 'Lens', _style:'width:20%' },
        { key: 'pillar_id', label: 'Pillar', _style:'width:20%' },
        { key: 'improvement_guidance_url', label: 'URL', _style:'width:10%', sorter: false, filter: false },
        { key: 'improvement_view', label: 'Item',  _style: 'width:1%', sorter: false, filter: false },
        { key: 'improvement_view_html', label: 'HTML',  _style: 'width:1%', sorter: false, filter: false }
      ],
      lenses: [],
      pillars: [],
      loadingImprovementItemsTable: true,
      loadingLenses: true,
      loadingPillars: true,
      viewImprovementModal: false,
      viewImprovementHtmlModal: false,
      viewImprovementItem: {},
      viewImprovementHtmlItem: {},
      viewImprovementItemHtmlPlain: "",
      activePage: 1,
      selectedLens: "",
      selectedLensAlias: "",
      selectedPillar: "",
      selectedPillarId: "",
      showTooltip: false,
      editorOption: {
        modules: {
          toolbar: false
        },
        placeholder: 'No HTML found!',
        readOnly: true,
      },
      showSuccessToast: false,
      showErrorToast: false,
      showInfoToast: false,
      successToastMessage: "",
      errorToastMessage: "",
      infoToastMessage: "",
      updateStatus: {},
      updateLock: {}

    };
  },

  methods: {
    transformUpdateStatusToColor(){
      if (this.updateStatus.status === "SUCCESSFULLY"){
        return "success";
      }
      else if (this.updateStatus.status === "IN_PROGRESS"){
        return "info";
      } else {
        return "danger";
      }

    },
    transformUpdateStatus(){
      let last_update = new Date(this.updateStatus.updated).toLocaleString();

      return "Last update: " + last_update + " | Status: " + this.updateStatus.status;
    },      
    checkIfUpdateIsLocked(){
      if(this.updateLock.locked === true){
        return true;
      } else {
        return false;
      }
    },  
    lensSelectCustomLabel(lens){
      return `${lens.lens_name} [${lens.owner}]`
    },      
    getLensName(lensAlias) {
      let lens = this.lenses.find(o => o.lens_alias === lensAlias);

      if (lens === undefined) {
        return lensAlias
      }

      return lens.lens_name;
    
    },    
    getPillarName(pillarId) {
      let pillar = this.pillars.find(o => o.pillar_id === pillarId);

      if (pillar === undefined) {
        return pillarId
      }

      return pillar.pillar_name;
    
    },    
   updateImprovements() {
     ImprovementService.triggerImprovementsUpdater().then(
        response => {
          this.successToastMessage = "Database update started! This process can take up to 5 minutes.";
          this.showSuccessToast = true;
         },
        error => {   
          console.log(error);
          this.errorToastMessage = "Error starting the database update. Please try again later.";
          this.showErrorToast = true;
        }
      )
    },    
    setFilterLens(){      
      if( this.selectedLens == null) {
        this.selectedLensAlias = "";
      } else {
        this.selectedLensAlias = this.selectedLens.lens_alias || "";
      };
    },
    setFilterPillar(){      
      if(this.selectedPillar == null){
        this.selectedPillarId = "";
      } else {
        this.selectedPillarId = this.selectedPillar.pillar_id || "";
      };
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    } ,
    viewImprovement(improvement) {
      this.viewImprovementItem = improvement
      this.viewImprovementModal = true
    },
    copyToClipBoard(textToCopy){
      this.showInfoToast = false;
      navigator.clipboard.writeText(textToCopy);      
      this.infoToastMessage = "Copied to clipboard!";
      this.showInfoToast = true;
    },
    viewImprovementHtml(cloud, lens_alias, pillar_id, choice_id) {
      ImprovementService.getImprovementByLensPillarChoice(cloud, lens_alias, pillar_id, choice_id).then(
        response => { 
          this.viewImprovementHtmlItem = response
          this.viewImprovementHtmlModal = true
          this.viewImprovementItemHtmlPlain = this.b64DecodeUnicode(this.viewImprovementHtmlItem.improvement_guidance_html)
        },
        error => {   
          console.log(error)   
        }
      )
    },
    b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    },
  },

  async created() {
    this.selectedCloud = this.$route.params.cloud
  },
  async mounted() {
    ImprovementService.getImprovementStatus('aws').then(
      response => { 
        this.updateStatus = response;
      },
      error => {   
        console.log(error)   
      }
    )  
    ImprovementService.getImprovementLock('aws').then(
      response => { 
        this.updateLock = response;
      },
      error => {   
        console.log(error)   
      }
    )          
    await LensService.getLenses().then(
      response => { 
        this.lenses = response.lens_summaries;
        this.loadingLenses = false;
      },
      error => {   
        console.log(error)   
      }      
    )
    await LensService.getPillars().then(
      response => { 
        this.pillars = response.pillars;
        this.loadingPillars = false;
      },
      error => {   
        console.log(error)   
      }      
    )
    await ImprovementService.getImprovements('aws').then(
      response => { 
        this.items = response.items;
        const indexLock = this.items.findIndex(obj => obj.improvement_id === "lock");
        if (indexLock > -1) { 
          this.items.splice(indexLock, 1); 
        }
        const indexStatus = this.items.findIndex(obj => obj.improvement_id === "status");

        if (indexStatus > -1) { 
          this.items.splice(indexStatus, 1); 
        }
        this.loadingImprovementItemsTable = false;
      },
      error => {   
        console.log(error)   
      }
    )
    this.loading = false;
  
  },

  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
